export function getIconUrl(el: string) {
  let iconurl = new URL(`../assets/icons/${el}.svg`, import.meta.url)
  return iconurl.href
}
export enum ICON {
  //VIEWER SWITCH
  bungalowSelect = 'Bungalow_Auswahl_34',
  bungalowGroundfloor = 'Bungalow_Erdgeschoss_35',
  houseSelect = 'Haus_Auswahl_30',
  houseGroundfloor = 'Untergeschoss_Auswahl_28',
  houseFirstfloor = 'Obergeschoss_Auswahl_27',
  //UNITY VIEWER
  _360 = '360',
  mouse = 'Maus',
  wasd = 'WASD',
  //FLOOR INFO
  floorInfoDimensions = 'Pfeile_57',
  floorInfoRoomstamps = 'm-hoch2-56',
  //INTERIOR VIEW
  interiorView = 'Fussspuren_45',
  //HOUSETYPE
  etagenAllgemein = 'Etagen_Allgemein_06',
  floorSpace = 'Grundflaeche_31',
  energyStandard = 'Energiestandard_07',
  //HOUSESIZE
  dimensions = 'Aussenmasse_02',
  numberPersons = 'Person_17',
  numberRooms = 'Zimmeranzahl_05',
  //ROOFTYPE
  roofPitch = 'Dachneigung_04',
  //INCLUSIVE SERVICES
  priceWarranty = 'Preisgarantie_08',
  basis = 'Basis_09',
  comfort = 'Komfort_10',
  safety = 'Sicherheit_13',
  energySaving = 'EnergieSpar_12',
  service = 'Service_11',
}
