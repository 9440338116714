<template>
  <section class="col-span-2 row-start-2 bg-gray-600 row-end-navigationFooter">
    <Carousel :data="imgdata" data-cy="carousel" />

    <Button
      :msg="`${$t('home.callToAction')}`"
      class="absolute bottom-5 left-3 right-3 overflow-visible text-xl tablet:bottom-auto tablet:left-24 tablet:right-auto tablet:top-[45%] tablet:px-16 tablet:py-4 tablet:text-xl"
      data-action="configuratorstart"
      data-cy="start-button"
      primary
      @click="startHandler"
    />
  </section>
</template>

<script lang="ts" setup>
import router from '../router'
import Button from '../components/common/Button.vue'
import Carousel from '../components/common/Carousel.vue'
import pathConstants from '../router/constants'
import i18n from '../i18n'
import {useHead} from '@vueuse/head'

useHead({
  title: `${i18n.global.t('pageTitles.main')}`,
  meta: [
    {
      name: 'description',
      content: `${i18n.global.t('pageMetaDescriptions.main')}`,
    },
  ],
})

const assetEnv = import.meta.env.VUE_ASSET_ENV
const cloudfrontUrl = import.meta.env.VUE_APP_CLOUDFRONT_URL

const imgdata = [
  {
    imgpath: `${cloudfrontUrl}${assetEnv}/assets/homeWallpaper/handy.jpg`,
    i18nkey: 'handy',
  },
  {
    imgpath: `${cloudfrontUrl}${assetEnv}/assets/homeWallpaper/laptop.jpg`,
    i18nkey: 'laptop',
  },
  {
    imgpath: `${cloudfrontUrl}${assetEnv}/assets/homeWallpaper/chill.jpg`,
    i18nkey: 'chill',
  },
]

const startHandler = () => {
  setTimeout(async () => {
    await router.push(pathConstants.Configurator.ROOT)
  }, 500)
}
</script>

<style></style>
