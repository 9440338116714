<template>
  <div class="relative col-span-2 row-start-2 row-end-navigationFooter">
    <img
      :src="cloudfrontUrl + assetEnv + '/assets/existing_background.jpeg'"
      alt="background"
      class="h-full w-full object-cover opacity-20"
    />
    <div class="absolute inset-0 flex items-center justify-center">
      <div class="m-3 flex w-full flex-col tablet:w-8/12 desktop:w-6/12 desktop:max-w-2xl">
        <HouseConsultant
          :text="[
            $t('existing.hello'),
            $t('existing.message', {date: lastVisitedDate, housename: lastVisitedHousename}),
          ]"
          class="mr-10 self-start text-xs tablet:text-base"
        />
        <div
          class="relative mt-6 flex flex-col-reverse gap-3 tablet:left-24 tablet:flex-row tablet:gap-6"
        >
          <Button
            :msg="$t('existing.configureNewHouse')"
            class="justify-center shadow-md tablet:text-xl"
            @click="useNewConfig"
          />
          <Button
            :msg="$t('existing.keepConfiguration')"
            class="justify-center shadow-md tablet:text-xl"
            primary
            @click="loadConfig"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Button from '../components/common/Button.vue'
import {computed} from 'vue'
import router from '../router'
import HouseConsultant from '../components/common/HouseConsultant.vue'
import i18n from '../i18n'
import {useConfigurationStore} from '../store/configuration'
import {useUserStore} from '../store/user'
import {useRoute} from 'vue-router'
import {useNewConfig} from '../composables/useNewConfig'
import {useHead} from '@vueuse/head'

useHead({
  title: `${i18n.global.t('pageTitles.existingConfig')} | ${i18n.global.t('pageTitles.main')}`,
  meta: [
    {
      name: 'description',
      content: `${i18n.global.t('pageMetaDescriptions.existingConfig')}`,
    },
  ],
})

const configurationStore = useConfigurationStore()
const userStore = useUserStore()

const route = useRoute()

const assetEnv = import.meta.env.VUE_ASSET_ENV
const cloudfrontUrl = import.meta.env.VUE_APP_CLOUDFRONT_URL

const selectedHouseType = computed(() => configurationStore.selectedHouseType)
const selectedHouseSize = computed(() => configurationStore.selectedHouseSize)
const lastVisitedTime = computed(() => userStore.lastVisitedConfiguratorTime)
const lastVisitedPath = computed(() => userStore.lastVisitedConfiguratorPath)

const lastVisitedDate = computed(() => {
  if (lastVisitedTime.value) {
    return new Date(lastVisitedTime.value).toLocaleString(i18n.global.locale.value, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }
  return ''
})

const lastVisitedHousename = computed(() => {
  if (selectedHouseType.value && selectedHouseSize.value) {
    return selectedHouseSize.value.name_i18n[i18n.global.locale.value]
  }
  return ''
})

const loadConfig = async () => {
  userStore.setLastVisitedConfigurationTime(new Date(Date.now()))
  await router.push(lastVisitedPath.value)
}
</script>
