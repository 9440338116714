<template>
  <Popup :showPopup="showModal" @close="showModal = false" :header="$t('error.popup.header')">
    <template v-slot:content>
      <div v-html="$t('error.popup.content')"></div>
    </template>

    <template v-slot:footer>
      <Button primary @click="handleReload" :msg="$t('error.popup.reload')" />
      <Button primary @click="handleChangeToFallback" :msg="$t('error.popup.picture')" />
    </template>
  </Popup>
</template>

<script lang="ts" setup>
import Popup from '../common/Popup.vue'
import Button from '../common/Button.vue'
import {ref} from 'vue'
import {useEventBus} from '@vueuse/core'
import {eventBus} from '../../eventBus'

const props = defineProps<{ showFallback: boolean }>();
const emit = defineEmits<{ 
  (e: 'update-show-fallback'): void,
  (e: 'reload-Unity'): void,
}>();

const showModal = ref(false)

const bus = useEventBus(eventBus)

function handleChangeToFallback() {
  bus.emit({ name: 'update-show-fallback' });
  emit('update-show-fallback');
  showModal.value = false;
}

const handleReload  = () => {
  showModal.value = false;
  location.reload()
}

bus.on(event => {
  if (event.name === 'apiError' || event.name === 'showErrorPopup') {
    showModal.value = true
  }
})
</script>

<style lang="postcss" scoped>
:deep(a) {
  @apply text-primary underline;
}
</style>