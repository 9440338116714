<template>
  <div
    :class="minimal ? 'fixed top-0 z-30 h-0 bg-transparent' : 'relative h-full bg-primary'"
    class="relative flex h-full flex-row items-center overflow-hidden grid-in-appbar desktop:grid desktop:grid-cols-3"
  >
    <!-- MenuIcon -->
    <div
      :class="[
        minimal ? 'fixed left-2 top-2 h-8 w-8' : 'h-12 w-12 justify-self-start',
        !menuOpen && minimal && 'rounded-lg border-gray-200 bg-white shadow-md',
      ]"
      class="z-[45] flex cursor-pointer items-center justify-center text-gray-800"
      data-cy="menu-button"
      @click="toggleMenu()"
    >
      <Icon v-if="menuOpen" class="transition hover:rotate-180" faIcon="xmark" />
      <Icon v-else class="transition hover:rotate-180" data-action="offcanvas" faIcon="bars" />
    </div>

    <!-- Logo -->
    <img
      v-if="!minimal"
      :src="cloudfrontUrl + assetEnv + '/assets/logo_bigger.svg'"
      alt="logo"
      class="mx-1 h-7 cursor-pointer justify-self-center desktop:h-8"
      data-action="logo"
      data-cy="appbar-logo"
      @click="logoClick"
    />

    <!-- Price -->
    <transition name="price">
      <div
        v-if="showPrice && minimal"
        :class="
          minimal
            ? 'fixed right-0 top-2 flex h-8 items-center justify-center rounded-lg border-gray-200 bg-white px-2 text-black shadow-md'
            : ' text-white'
        "
        class="mr-2 flex grow justify-end text-right tablet:mr-6"
      >
        <div
          class="w-fit cursor-pointer"
          data-cy="appbar-price"
          @click="showPriceInfoModal = !showPriceInfoModal"
        >
          <div v-if="!minimal" class="text-xs">{{ $t('navigation.priceComplete') }}:</div>
          <div class="flex items-center justify-end text-sm font-bold leading-3 desktop:text-base">
            <div class="flex items-center justify-center" data-action="price">
              <Icon
                v-tooltip="$t('tooltips.pricetag')"
                class="desktop:text-md px-1 text-black desktop:px-2"
                faIcon="circle-info"
              />
              <AnimatedNumber :euro="true" :number="totalPrice" />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Menu -->
    <transition name="menu">
      <NavigationDrawer
        v-if="menuOpen"
        :class="minimal ? 'top-0 pt-12' : 'top-12'"
        :toggleMenu="toggleMenu"
        class="fixed bottom-0 left-0 z-30 w-10/12 max-w-xs"
        data-cy="navigation-drawer"
      />
    </transition>
    <!-- Backdrop -->
    <transition name="backdrop">
      <div
        v-show="menuOpen"
        :class="minimal ? 'top-0' : 'top-12'"
        class="backdrop fixed bottom-0 left-0 z-[41] w-full"
        @click="toggleMenu"
      ></div>
    </transition>
  </div>
  <!-- Price Info -->
  <PriceInfo :closeModal="() => (showPriceInfoModal = false)" :showModal="showPriceInfoModal" />
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue'
import router from '../../router'
import pathConstants from '../../router/constants'
import NavigationDrawer from '../menu/NavigationDrawer.vue'
import AnimatedNumber from './AnimatedNumber.vue'
import PriceInfo from './PriceInfo.vue'
import {useRoute} from 'vue-router'
import {useConfigurationStore} from '../../store/configuration'
import {useUserStore} from '../../store/user'
import Icon from './Icon.vue'
import {useEventBus} from '@vueuse/core'
import {eventBus} from '../../eventBus'
import {status} from '@/util/status'
import i18n from '@/i18n'
import {useNewConfigToHome} from '@/composables/useNewConfigToHome'

const props = defineProps({
  minimal: {type: Boolean, default: false},
})

const route = useRoute()

const DEVELOPMENT = import.meta.env.VUE_APP_DEVELOPMENT === 'true' ? true : false

const menuOpen = ref(false)
const showPriceInfoModal = ref(false)

const showPrice = computed(() => route.meta.configurator)

const assetEnv = import.meta.env.VUE_ASSET_ENV
const cloudfrontUrl = import.meta.env.VUE_APP_CLOUDFRONT_URL

const configurationStore = useConfigurationStore()
const userStore = useUserStore()

const configurationId = computed(() => configurationStore.configurationId)

const totalPrice = computed(() => configurationStore.totalPrice)

watch(
  () => route.path,
  () => {
    showPriceInfoModal.value = false
  }
)

const toggleMenu = () => {
  menuOpen.value = !menuOpen.value
}

const logoClick = () => {
  if (router.currentRoute.value.path !== pathConstants.HOME) {
    // at success or overview route directly to home
    if (
      router.currentRoute.value.path === pathConstants.SUCCESS ||
      router.currentRoute.value.path === pathConstants.OVERVIEW
    ) {
      useNewConfigToHome()

      // show popup while in configuration
    } else {
      const bus = useEventBus(eventBus)

      bus.emit({
        name: 'dialogPopup',
        data: {
          status: status.warning,
          header: `${i18n.global.t('dialogPopup.headerProcess')}`,
          message: `${i18n.global.t('dialogPopup.messageProcess')}`,
          process: true,
        },
      })
    }
  }
}
</script>

<style>
.menu-enter-active {
  transition: all 0.5s ease-out;
}

.menu-leave-active {
  transition: all 0.5s ease-in;
}

.menu-enter-from,
.menu-leave-to {
  transform: translateX(-1000px);
  opacity: 0;
}

.price-enter-active {
  transition: all 0.5s ease-out;
}

.price-leave-active {
  transition: all 0.5s ease-in;
}

.price-enter-from,
.price-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

.backdrop {
  background-color: black;
  opacity: 0.6;
}

.backdrop-enter-active {
  transition: all 0.3s ease-out;
}

.backdrop-leave-active {
  transition: all 0.3s ease-in;
}

.backdrop-enter-to,
.backdrop-leave-from {
  opacity: 0.6;
}

.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}
</style>
