<template>
  <div v-if="showPopup" class="fixed inset-0 z-50 flex bg-black bg-opacity-50" @click="close" data-cy="popup-bg">
    <div
      class="fixed bottom-0 m-auto flex max-h-[80vh] w-full flex-col overflow-hidden rounded-t-xl bg-white py-3 px-3 text-left tablet:relative tablet:max-h-[85vh] tablet:w-fit tablet:max-w-[1000px] tablet:rounded-xl tablet:py-6 tablet:px-6"
      @click.stop
      data-cy="popup-modal"
    >
      <div v-if="header" class="text-md mb-2 font-bold tablet:text-lg">{{ header }}</div>
      <div class="mt-3 overflow-y-auto tablet:mt-0">
        <slot name="content"></slot>
      </div>
      <div class="mt-8 flex justify-evenly" data-cy="popup-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  showPopup: boolean
  preventBgClick?: boolean
  header?: string
}>()

const emit = defineEmits(['close'])

const close = () => {
  if (!props.preventBgClick) {
    emit('close')
  }
}
</script>

<style scoped></style>
