<template>
  <!-- LOADING SCREEN -->
  <LoadingScreen v-if="loading" v-show="show" :percent="loadingPercent" />
  <!-- UNITY -->
  <div
    v-show="show"
    :class="[
      fullScreen
        ? 'absolute inset-0 !z-[49]'
        : 'relative row-start-appbar row-end-viewer col-start-viewer col-end-viewer tablet:grid-in-viewer',
      hideTabNav && 'tablet:row-start-navbar',
    ]"
    class="unity_viewer z-20 overflow-hidden"
    data-cy="unity-viewer"
  >
    <!-- UNITY CANVAS -->
    <UnityVue
      :unity="Unity"
      ref="unityCanvasRef"
      class="h-full w-full !cursor-move"
      data-action="unityviewer"
      tabindex="1"
      @click="lockCursor()"
      id="unity_container"
    />
    <ViewerSwitch :viewModeInside="viewModeInside" />
    <!-- FULLSCREEN ICON -->
    <div class="">
      <div
        v-tooltip="
          fullScreen ? $t('tooltips.toggleFullscreenOff') : $t('tooltips.toggleFullscreenOn')
        "
        class="absolute bottom-0 right-0 cursor-pointer p-2 pl-4 pt-4"
        data-cy="toggle-fullscreen"
        @click="() => (fullScreen = !fullScreen)"
      >
        <Icon
          :faIcon="fullScreen ? 'compress' : 'expand'"
          class="align-middle text-xl transition-all hover:text-gray-600"
          data-action="fullscreen"
        />
      </div>
    </div>

    <!-- INTERIOR VIEW CONTROL ICONS -->
    <Transition name="controls">
      <div v-if="showControls">
        <div class="absolute bottom-2 right-14 opacity-60">
          <Icon :svgIcon="ICON.mouse" class="w-20" />
        </div>
        <div class="absolute bottom-2 left-40 opacity-60">
          <Icon :svgIcon="ICON.wasd" class="w-36" />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, onUnmounted} from 'vue'
import i18n from '../../i18n'
import UnityWebgl from 'unity-webgl'
import UnityVue from 'unity-webgl/vue'
import {computed, ref, watch} from 'vue'
import {useConfigurationStore} from '../../store/configuration'
import {useRoute} from 'vue-router'
import {CameraPosition} from '../../router'
import {useEventBus, useEventListener} from '@vueuse/core'
import {eventBus} from '../../eventBus'
import ViewerSwitch from './ViewerSwitch.vue'
import {useUnityStore} from '../../store/unity'
import Icon from '../common/Icon.vue'
import {ICON} from '../../util/icons'
import useDeviceInfo, {DeviceInfo} from '../../composables/useDeviceInfo'
import LoadingScreen from './LoadingScreen.vue'
import {useAppStore} from '../../store/app'
import * as Sentry from '@sentry/vue'
import {MirrorState, VehicleStorage} from '../../store/configurationTypes'
import {useFullscreen} from '@vueuse/core'
import pathConstants from '../../router/constants'

interface UpdateJsonType {
  houseLine: string
  houseType: string
  roof: string
  viewMode: unknown
  floorplanEg: string
  floorplanOg: string
  deviceInfo: DeviceInfo
  language: any
  showFloorInfo: boolean
  showDimensionLines: boolean
  debugMode: boolean
  mirrorState: string
}

const bus = useEventBus(eventBus)

const props = defineProps({
  show: Boolean,
})

const route = useRoute()
const cameraPosition = computed(() => route.meta.camera)
const hideTabNav = computed(() => route.meta.hideTabNav)
const unityCamera = ref(cameraPosition.value)
const unityCanvasRef = ref(null)

const appStore = useAppStore()
const devMode = computed(() => appStore.devMode)
const loading = ref(true)
const fullScreen = ref(false)
const showFloorInfo = ref(true)
const showErrorPopup = ref(false)
const showDimensions = ref(false)
const viewModeInside = ref(false)
const showControls = ref(false)

const configurationStore = useConfigurationStore()

const floorPlanMirroredVert = computed(() => configurationStore.floorPlanMirroredVert)
const floorPlanMirroredHoriz = computed(() => configurationStore.floorPlanMirroredHoriz)

const storeReady = computed(() => configurationStore.storeReady)
const selectedHousetype = computed(() => configurationStore.selectedHouseType)
const selectedHousesize = computed(() => configurationStore.selectedHouseSize)
const selectedRooftype = computed(() => configurationStore.selectedRoofType)
const selectedGroundFloor = computed(() => configurationStore.selectedGroundFloor)
const selectedFirstFloor = computed(() => configurationStore.selectedFirstFloor)
const selectedVehicleStorage = computed(() =>
  configurationStore.selectedAdditionalServices.find(x => x.category === 'garage' || 'carport')
)

const unityStore = useUnityStore()

const unityEnv = import.meta.env.VUE_UNITY_ENV
const unityVersion = import.meta.env.VUE_UNITY_VERSION
const unityPath = import.meta.env.VUE_APP_CLOUDFRONT_URL + unityEnv + '/unity/' + unityVersion
const unitySceneFiles = unityPath + '/3DScene'
const streamingAssetsUrl = unityPath + '/StreamingAssets'

let unityTimeout: number | null = null
unityTimeout = window.setTimeout(() => {
  bus.emit({name: 'showErrorPopup'})
  console.error('Unity failed to load within the expected time.') // expected time: 10s
}, 10000)

const Unity = new UnityWebgl('#unity_container', {
  loaderUrl: unitySceneFiles + '.loader.js',
  dataUrl: unitySceneFiles + '.data.br',
  frameworkUrl: unitySceneFiles + '.framework.js.br',
  codeUrl: unitySceneFiles + '.wasm.br',
  productVersion: unityEnv + '/unity/' + unityVersion,
  streamingAssetsUrl: streamingAssetsUrl,
})

function changeToFallback() {
  Unity.unload()
}

const {isFullscreen, toggle: toggleFullscreen} = useFullscreen()

watch(isFullscreen, newValue => {
  fullScreen.value = newValue
  Unity.send(`Receiver`, 'UpdateScreenSize')
})

const handleResize = () => {
  Unity.send(`Receiver`, 'UpdateScreenSize')
}

useEventListener(window, 'resize', handleResize)

const toggleFullscreenAndUpdate = () => {
  toggleFullscreen()
}

Unity.on('created', () => {
  window.clearTimeout(unityTimeout)
  useEventListener(window, 'unityEvent', (e: Event) => unityEventHandler(e))
})

// on progress for loadingscreen
const loadingPercent = ref(0)
Unity.on('progress', (progress: number) => {
  loadingPercent.value = Math.floor(progress * 100)
})

// init fullscreen by horizontal view
useEventListener(window, 'orientationchange', (e: Event) => {
  fullScreen.value = Math.abs(e.target.orientation) === 90
})

const closeFullscreen = () => {
  if (fullScreen.value) {
    fullScreen.value = false
    if (unityCamera.value === 'Inside') {
      // Reset camera position when exiting fullscreen from inside view
      unityCamera.value = cameraPosition.value
      viewModeInside.value = false
      showControls.value = false
      bus.emit({
        name: 'showMirroring',
      })
      handleResize()
      sendUpdateScene()
    }
  }
}

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    closeFullscreen()
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown)
})

const getUpdateJson = async (scene: string) => {
  let mirrorState = MirrorState.none
  if (floorPlanMirroredVert.value && floorPlanMirroredHoriz.value) {
    mirrorState = MirrorState.both
  } else if (floorPlanMirroredVert.value) {
    mirrorState = MirrorState.vert
  } else if (floorPlanMirroredHoriz.value) {
    mirrorState = MirrorState.horiz
  }

  let vehicleStorage = VehicleStorage.none
  if (selectedVehicleStorage.value) {
    if (selectedVehicleStorage.value.name === 'EzCa') {
      vehicleStorage = VehicleStorage.carport
    } else if (selectedVehicleStorage.value.name === 'EzGa') {
      vehicleStorage = VehicleStorage.garage
    } else if (selectedVehicleStorage.value.name === 'DoCa') {
      vehicleStorage = VehicleStorage.doubleCarport
    } else if (selectedVehicleStorage.value.name === 'DoGa') {
      vehicleStorage = VehicleStorage.doubleGarage
    } else if (selectedVehicleStorage.value.name === 'DoCaLa') {
      vehicleStorage = VehicleStorage.doubleCarportWithStorage
    } else if (selectedVehicleStorage.value.name === 'DoGaLa') {
      vehicleStorage = VehicleStorage.doubleGarageWithStorage
    } else if (selectedVehicleStorage.value.name === 'EzCaLa') {
      vehicleStorage = VehicleStorage.carportWithStorage
    } else if (selectedVehicleStorage.value.name === 'EzGaLa') {
      vehicleStorage = VehicleStorage.garageWithStorage
    }
  }
  const configurations = await getConfigurations(scene)

  return {
    houseLine: selectedHousetype.value?.name || '',
    houseType: selectedHousesize.value?.name || '',
    roof: selectedRooftype.value?.name.split('_').pop() || '',
    viewMode: unityCamera.value || CameraPosition.house,
    subViewMode: 'None',
    floorplanEg: selectedGroundFloor.value ? selectedGroundFloor.value?.name : '',
    floorplanOg: selectedFirstFloor.value ? selectedFirstFloor.value?.name : '',
    deviceInfo: useDeviceInfo(),
    language: i18n.global.locale.value || 'de',
    showFloorInfo: showFloorInfo.value,
    showDimensionLines: showDimensions.value,
    debugMode: devMode.value,
    mirrorState: mirrorState,
    unityVersion: unityEnv + '/unity/' + unityVersion,
    configurations: configurations,
    vehicleStorage: vehicleStorage,
  }
}
const getConfigurations = async (scene: string) => {
  if (scene === 'init' || route.path === pathConstants.Configurator.HOUSE_KIND) {
    return await configurationStore.loadDefaultConfigurations()
  } else if (scene === 'update' && route.path === pathConstants.Configurator.HOUSE_TYPE) {
    return await configurationStore.loadDefaultConfigurationsForHouseType()
  }
  return []
}

const isJsonValid = (updateJson: UpdateJsonType) => {
  if (updateJson.floorplanEg === '') return false
  else if (selectedHousesize.value?.floors === 1 && updateJson.floorplanOg !== '') return false
  else if (selectedHousesize.value?.floors === 2 && updateJson.floorplanOg === '') return false
  else if (!updateJson.floorplanEg.includes(updateJson.houseType)) return false
  else if (updateJson.floorplanOg !== '' && !updateJson.floorplanOg.includes(updateJson.houseType))
    return false
  return true
}

const lockCursor = () => {
  const canvasFrame = document.getElementById('unity_container')
  if (!canvasFrame) return
  canvasFrame.addEventListener('mousedown', e => {
    if (e.button === 0) {
      canvasFrame.requestPointerLock()
    }
  })
  canvasFrame.addEventListener('mouseup', e => {
    if (e.button === 0) {
      document.exitPointerLock()
    }
  })
}

const sendInitSceneIfReady = async () => {
  if (unityStore.unityReady && configurationStore.storeReady) {
    sendToUnity('InitScene', await getUpdateJson('init'))
    unityStore.setUnityInitialized()
  }
}

const sendUpdateScene = async () => {
  if (unityStore.unityInitialized) {
    sendToUnity('UpdateScene', await getUpdateJson('update'))
  }
}

// handle unity events
const unityEventHandler = async (event: any) => {
  const eventType = event.detail.name

  if (eventType === 'UnityInitialized') {
    unityStore.setUnityReady()
    loading.value = false
  }

  if (eventType === 'UnityError') {
    const error = event.detail.error
    bus.emit({name: 'apiError'})
    console.log('Caught Unity Error', error)
    Sentry.captureException(error, {
      contexts: {
        json: await getUpdateJson('update'),
      },
    })
  }

  if (eventType === 'ImageExport') {
    const images = event.detail.images
    window._images = images

    const readyDiv = document.createElement('div')
    readyDiv.setAttribute('id', 'ready')
    document.querySelector('body')?.appendChild(readyDiv)
  }

  if (eventType === 'HousePosition') {
    const house_position = event.detail.message
    configurationStore.setHousePosition(house_position)
  }
}

const sendToUnity = async (func: string, updateJson: UpdateJsonType) => {
  try {
    // only send to unity if json is valid
    if (isJsonValid(updateJson)) {
      Unity.send(`Receiver`, func, updateJson)

      // log json if devMode on
      if (devMode.value) {
        console.log(updateJson)
      }
    }
  } catch (error) {
    console.log('Caught Unity Error', error)
    Sentry.captureException(error, {
      contexts: {
        json: await getUpdateJson('update'),
      },
    })
  }
}

// handle frontend events
bus.on(async event => {
  switch (event.name) {
    case 'exportImages':
      console.log('Send export images to unity')
      sendToUnity('ExportImages', await getUpdateJson('update'))
      break
    case 'setCameraPosition':
      unityCamera.value = event.data
      sendUpdateScene()
      break
    case 'toggleFloorInfo':
      showFloorInfo.value = !showFloorInfo.value
      sendUpdateScene()
      break
    case 'toggleFloorDimensions':
      showDimensions.value = !showDimensions.value
      sendUpdateScene()
      break
    case 'toggleInteriorView':
      if (unityCamera.value !== 'Inside') {
        // set fullscreen
        fullScreen.value = true

        // set Viewmode to Inside
        unityCamera.value = 'Inside'

        // set prop for Viewer Switch
        viewModeInside.value = true

        // show Control Icons for 10 seconds
        showControls.value = true
        setTimeout(() => {
          showControls.value = false
        }, 10000)

        // hide mirroring checkboxes
        bus.emit({
          name: 'hideMirroring',
        })
      } else {
        // leave fullscreen
        fullScreen.value = false

        // set correct camera
        if (event.data === 0) {
          unityCamera.value = CameraPosition.groundFloor
        } else if (event.data === 1) {
          unityCamera.value = CameraPosition.firstFloor
        } else {
          unityCamera.value = cameraPosition.value
        }

        showControls.value = false
        viewModeInside.value = false

        // show mirroring checkboxes
        bus.emit({
          name: 'showMirroring',
        })
      }
      sendUpdateScene()
      break
    case 'reload-Unity':
      reloadViewer()
      break
    case 'update-show-fallback':
      changeToFallback()
      break
  }
})

// watcher on unityReady and storeReady for init scene
// -----------------------------------------------------

watch(
  () => unityStore.unityReady,
  val => {
    sendInitSceneIfReady()
  }
)
watch(
  () => configurationStore.storeReady,
  val => {
    sendInitSceneIfReady()
  }
)
// -----------------------------------------------------

// watcher on mirroring value vertical and horizontal
// -----------------------------------------------------
watch(
  () => configurationStore.floorPlanMirroredVert,
  val => {
    sendUpdateScene()
  }
)
watch(
  () => configurationStore.floorPlanMirroredHoriz,
  val => {
    sendUpdateScene()
  }
)
// -----------------------------------------------------

watch(
  () => cameraPosition.value,
  val => {
    fullScreen.value = false
    unityCamera.value = cameraPosition.value
    viewModeInside.value = false
    showControls.value = false
    sendUpdateScene()
  }
)

watch(
  () => devMode.value,
  val => {
    sendUpdateScene()
  }
)

watch(
  () => i18n.global.locale.value,
  val => {
    sendUpdateScene()
  }
)

watch(
  () =>
    configurationStore.selectedAdditionalServices.find(x => x.category === 'garage' || 'carport'),
  val => {
    sendUpdateScene()
  }
)

watch(
  () => viewModeInside.value,
  newValue => {
    if (newValue && unityCanvasRef.value) {
      // Focus the UnityCanvas component
      unityCanvasRef.value.$el.focus()
    }
  }
)
// watch(
//     () => showControls.value,
//     val => {
//       if (val) {
//         setTimeout(() => {
//           showControls.value = false
//         }, 2000)
//       }
//     }
// )

configurationStore.$onAction(({name, store, after}) => {
  const events = [
    'selectHouseType',
    'selectHouseSize',
    'selectRoofType',
    'selectProperty',
    'selectGroundFloor',
    'selectFirstFloor',
    'randomizeConfiguration',
  ]

  if (events.includes(name)) {
    after(() => {
      sendUpdateScene()
    })
  }
})

const closeErrorPopup = async () => {
  window.localStorage.clear()
  location.reload()
  showErrorPopup.value = false
}
</script>

<style scoped>
._360-enter-active,
._360-leave-active {
  transition: opacity 0.5s ease;
}

._360-enter-from,
._360-leave-to {
  opacity: 0;
}

.controls-enter-active,
.controls-leave-active {
  transition: opacity 0.5s ease;
}

.controls-enter-from,
.controls-leave-to {
  opacity: 0;
}
</style>
