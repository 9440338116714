<template>
  <div
    class="col-span-2 row-start-2 overflow-y-auto bg-white text-justify row-end-navigationFooter tablet:text-base"
  >
    <div class="px-8 py-8 tablet:px-20">
      <h2 class="mb-4 text-2xl font-bold text-primary">{{ $t('faqinfo.title') }}</h2>
      <p v-html="$t('faqinfo.desc')"></p>
    </div>
    <div class="px-8 tablet:px-20">
      <!--  Ziegler Haus Mehrwert -->
      <div class="mb-6 rounded-lg border border-gray-400 shadow-md" data-cy="faq-added-value">
        <h2 class="mt-2 px-4 font-bold text-primary">{{ $t('faqheadlines[0]') }}</h2>
        <Accordeon class="m-0" :header="$t('faq[0].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[0].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[1].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[1].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[2].title')" flat>
          <template v-slot:contentSlot>
            <p v-html="$t('faq[2].desc')"></p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[3].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[3].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[4].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[4].desc') }}
            </p>
          </template>
        </Accordeon>
      </div>
      <!-- Bauweise & Produktion -->
      <div
        class="mb-6 rounded-lg border border-gray-400 shadow-md"
        data-cy="faq-construction-production"
      >
        <h2 class="mt-2 px-4 font-bold text-primary">{{ $t('faqheadlines[1]') }}</h2>
        <Accordeon class="m-0" :header="$t('faq[5].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[5].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[6].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[6].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[7].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[7].desc') }}
            </p>
          </template>
        </Accordeon>

        <Accordeon class="m-0" :header="$t('faq[8].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[8].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[9].title')" flat>
          <template v-slot:contentSlot>
            <p v-html="$t('faq[9].desc')"></p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[10].title')" flat>
          <template v-slot:contentSlot>
            <p v-html="$t('faq[10].desc')"></p>
            <div class="flex flex-col pt-4 tablet:flex-row">
              <img
                :src="cloudfrontUrl + assetEnv + '/assets/wall_construction.png'"
                alt=""
                class="tablet:h-64"
              />
              <div class="flex flex-col justify-center pt-4 tablet:pt-0">
                <div class="flex">
                  <p class="pr-1 font-bold">A:</p>
                  <p v-html="$t('wallConstruction[0]')"></p>
                </div>
                <div class="flex">
                  <p class="pr-1 font-bold">B:</p>
                  <p v-html="$t('wallConstruction[1]')"></p>
                </div>
                <div class="flex">
                  <p class="pr-1 font-bold">C:</p>
                  <p v-html="$t('wallConstruction[2]')"></p>
                </div>
                <div class="flex">
                  <p class="pr-1 font-bold">D:</p>
                  <p v-html="$t('wallConstruction[3]')"></p>
                </div>
                <div class="flex">
                  <p class="pr-1 font-bold">E:</p>
                  <p v-html="$t('wallConstruction[4]')"></p>
                </div>
                <div class="flex">
                  <p class="pr-1 font-bold">F:</p>
                  <p v-html="$t('wallConstruction[5]')"></p>
                </div>
              </div>
            </div>
          </template>
        </Accordeon>
      </div>
      <!-- Ausstattung & Technik -->
      <div
        class="mb-4 rounded-lg border border-gray-400 shadow-md"
        data-cy="faq-equipment-technology"
      >
        <h2 class="mt-2 px-4 font-bold text-primary">{{ $t('faqheadlines[2]') }}</h2>
        <Accordeon class="m-0" :header="$t('faq[11].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[11].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[12].title')" flat>
          <template v-slot:contentSlot>
            <p v-html="$t('faq[12].desc')"></p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[13].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[13].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[14].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[14].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[15].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[15].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[16].title')" flat>
          <template v-slot:contentSlot>
            <p v-html="$t('faq[16].desc')"></p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[17].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[17].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[18].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[18].desc') }}
            </p>
          </template>
        </Accordeon>
        <Accordeon class="m-0" :header="$t('faq[19].title')" flat>
          <template v-slot:contentSlot>
            <p>
              {{ $t('faq[19].desc') }}
            </p>
          </template>
        </Accordeon>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Accordeon from '../components/common/Accordeon.vue'
import i18n from '../i18n'
import {useHead} from '@vueuse/head'

useHead({
  title: `${i18n.global.t('pageTitles.faq')} | ${i18n.global.t('pageTitles.main')}`,
  meta: [
    {
      name: 'description',
      content: `${i18n.global.t('pageMetaDescriptions.faq')}`,
    },
  ],
})

const assetEnv = import.meta.env.VUE_ASSET_ENV
const cloudfrontUrl = import.meta.env.VUE_APP_CLOUDFRONT_URL
</script>
